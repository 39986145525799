const ACTIVE_FEATURE_FLAGS: string[] = [];

// image widths within the lead media.
const LEAD_MEDIA_SMALL_WIDTH = 375;
const LEAD_MEDIA_MEDIUM_WIDTH = 567;
const LEAD_MEDIA_LARGE_WIDTH = 700;
const LEAD_MEDIA_ASPECT_HEIGHT = 9;
const LEAD_MEDIA_ASPECT_WIDTH = 16;

// Screen widths
const LARGE_SCREEN_WIDTH_MIN = 1200;
const MEDIUM_SCREEN_WIDTH_MAX = 1199;
const MEDIUM_SCREEN_WIDTH_MIN = 992;
const SMALL_SCREEN_WIDTH_MAX = 991;
const SMALL_SCREEN_WIDTH_MIN = 768;
const EXTRA_SMALL_SCREEN_WIDTH_MAX = 767;

const FIXED_NAV_TABLET_HEIGHT = 64;
const TOP_TEN_PAGES: string[] = [
    "185-powerful-verbs-that-will-make-your-resume-awesome",
    "51-interview-questions-you-should-be-asking",
    "7-goodbye-email-templates-youll-need-when-you-quit-your-job",
    "how-to-gracefully-turn-down-a-job-offer",
    "how-to-write-a-resignation-letter",
    "how-to-write-an-interview-thankyou-note-an-email-template",
    "interview-questions-and-answers",
    "star-interview-method",
    "team-building-activities-games-for-work-office",
    "tell-me-about-yourself-interview-question-answer-examples",
    "test-listicle-5-21-2020", // This is to test on staging and local
];
const NEWSLETTER_EXCLUDED_PAGES: string[] = [
    "interview-questions-and-answers",
    "employer-resources",
];
const NEWSLETTER_EXCLUDED_TAGS: string[] = [
    "employer-resources"
];
const NEWSLETTER_EXCLUDED_PRIMARY_TOPICS: string[] = [
    "employer-resources"
];

const ADS_EXCLUDED_PRIMARY_TOPICS: string[] = [
    "Diversity & Inclusion",
    "Gender",
    "Age",
    "Race & Ethnicity",
    "Anti-Racism",
    "Disability",
    "LGBTQ",
    "Women in Tech",
    "Harassment"
];

export {
    ACTIVE_FEATURE_FLAGS,
    ADS_EXCLUDED_PRIMARY_TOPICS,
    LEAD_MEDIA_SMALL_WIDTH,
    LEAD_MEDIA_MEDIUM_WIDTH,
    LEAD_MEDIA_LARGE_WIDTH,
    LEAD_MEDIA_ASPECT_HEIGHT,
    LEAD_MEDIA_ASPECT_WIDTH,
    LARGE_SCREEN_WIDTH_MIN,
    MEDIUM_SCREEN_WIDTH_MAX,
    MEDIUM_SCREEN_WIDTH_MIN,
    SMALL_SCREEN_WIDTH_MAX,
    SMALL_SCREEN_WIDTH_MIN,
    EXTRA_SMALL_SCREEN_WIDTH_MAX,
    FIXED_NAV_TABLET_HEIGHT,
    NEWSLETTER_EXCLUDED_PAGES,
    NEWSLETTER_EXCLUDED_PRIMARY_TOPICS,
    NEWSLETTER_EXCLUDED_TAGS,
    TOP_TEN_PAGES
};
