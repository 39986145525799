import React from "react";

import { IConfig } from "@server/server";

/**
 * provides application configuration as a wrapper to components.
 * @example
 * <ConfigurationProvider>
 *     <MyComponent />
 * </ConfigurationProvider>
 * Then inside your component:
 * @example
 * import { Configuration } from "@advice/hooks/Configuration";
 * export const MyComponent: React.FC = () => {
 *     const { href } = useContext(Configuration);
 *     console.log({ href });
 * }
 */
const Configuration = React.createContext(({} as any) as IConfig);

const ConfigurationProvider: React.FC<IConfig> = props => {
    return <Configuration.Provider value={props}>{props.children}</Configuration.Provider>;
};

export { Configuration, ConfigurationProvider };
