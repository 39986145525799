import React from "react";
import { render } from "react-dom";

import { PageHeader } from "@advice/components/organisms/PageHeader";
import { ConfigurationProvider } from "@advice/hooks/Configuration";
import { LocationProvider } from "@advice/hooks/Location";
import { UserProvider } from "@advice/hooks/User";
import { IInitialState } from "@server/util/pageWrapper";
import { SearchDropdown } from "@advice/pages/shared/SearchDropdown";
import { initHeaderTracking } from "./initHeaderTracking";

export const initHeader = async (initialState: IInitialState) => {
    const { config, href, referrer, search, pathname } = initialState;
    const locationProps = { href, referrer, search, pathname, isAMP: false };

    render(
        <>
            <UserProvider>
                <LocationProvider {...locationProps}>
                    <ConfigurationProvider {...config}>
                        <PageHeader />
                        <SearchDropdown pageLocation="advice" />
                    </ConfigurationProvider>
                </LocationProvider>
            </UserProvider>
        </>,
        document.getElementById("page-header"),
        initHeaderTracking
    );
};
