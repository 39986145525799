import React, { useEffect, useState } from "react";

interface IUserCompany {
    id: number;
    name: string;
    short_name: string;
}
interface IUserProps {
    logged_in: boolean;
    view_bookings?: boolean;
    company?: IUserCompany;
    cropped_name?: string;
    email?: string;
    favorite_posts?: number[];
    name?: string;
    id?: number;
}

interface IUserProviderProps {
    user?: IUserProps;
}

const defaultUserState = {
    logged_in: false,
    view_bookings: false
};

const User = React.createContext<IUserProps>(defaultUserState);

const UserProvider: React.FC<IUserProviderProps> = props => {
    const initialState: IUserProps = props.user || defaultUserState;
    const [user, setUser] = useState(initialState);

    const checkUserData = () => {
        window.removeEventListener("storage", checkUserData);
        const item = window.localStorage.getItem("user");
        if (item && item !== user) {
            setUser(JSON.parse(item));
        }
        window.addEventListener("storage", checkUserData);
    };

    useEffect(() => {
        checkUserData();
        return () => {
            window.removeEventListener<"storage">("storage", checkUserData);
        };
    }, []);
    return <User.Provider value={user}>{props.children}</User.Provider>;
};

export { IUserProps, User, UserProvider };
