import React from "react";
import { render } from "react-dom";

import { EmailCapture } from "@advice/components/organisms/EmailCapture";
import { IInitialState } from "@server/util/pageWrapper";

export const initEmailCapture = async (initialState: IInitialState) => {
    const emailCaptureContainer = document.querySelector(".email-capture-container");
    if (!emailCaptureContainer) {
        return;
    }
    render(<EmailCapture {...initialState} />, emailCaptureContainer);
};
