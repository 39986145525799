import React from "react";
import { render } from "react-dom";

import { EXTRA_SMALL_SCREEN_WIDTH_MAX, MEDIUM_SCREEN_WIDTH_MAX } from "@advice/components/constants";
import { JobsBanner } from "@themuse/muse-front-end/dist/components/jobs_banner/component";
import { getAllowedLocationFromDMA, translateDMAString } from "@util/location";
import { getTrackingPropsAsDataAttrs, pushDataLayerEvent } from "@util/tracking";

import Axios from "axios";
import { formatLog } from "@util/logging";

const getDMAFromCarmenAPI = async () => {
    // DMA: Designated Market Area
    const sessionStorageDMA = window.sessionStorage.getItem("carmen_dma");
    if (sessionStorageDMA) {
        return sessionStorageDMA;
    }
    const { data } = await Axios.get("https://carmen.themuse.com/me");
    if (data.dma) {
        window.sessionStorage.setItem("carmen_dma", data.dma);
    }
    return data.dma;
};

const getBannerType = () => {
    const windowWidth = window.innerWidth;

    if (windowWidth <= EXTRA_SMALL_SCREEN_WIDTH_MAX) {
        return "mobile banner bottom";
    }
    if (windowWidth <= MEDIUM_SCREEN_WIDTH_MAX) {
        return "tablet bottom banner";
    }
    return "desktop right rail";
};

export const initJobsSidebar = async () => {
    const jobsSidebarElement = document.querySelector(".js-jobs-sidebar");
    const dataLayer = window.dataLayer[0];
    let dma = null;
    let location = null;

    if (jobsSidebarElement) {
        try {
            dma = await getDMAFromCarmenAPI();
            location = getAllowedLocationFromDMA(translateDMAString(dma));
        } catch (error) {
            const msg = `Advice-renderer was unable to fetch the current location from carmen within the Sidebar.`;
            console.error(msg, JSON.stringify(formatLog({ error })));
        }
        const bannerType = getBannerType();
        const eventProps = {
            banner_type: bannerType,
            click_text: "",
            dma_location: dma,
            experiments: [...dataLayer.experiments],
            placement: bannerType,
            source: "post-individual",
            used_location: location,
            sp_impression_component: "insert",
            sp_impression_insert_type: "hot_jobs",
            sp_impression_position: "right_rail",
            sp_impression_tile_type: null as null,
            sp_page_section: "advice",
            sp_page_type: "content",
            sp_click_component: "button",
            sp_click_button_type: "job_search",
            sp_click_component_tile_type: null as null,
            sp_click_insert_type: "hot_jobs",
            sp_click_button_text: "explore open jobs",
            sp_click_position: "right_rail",
            sp_click_target_page_section: "search"
        };

        let trackingProps = {
            clickEvent: "adviceJobsListingClicked",
            viewEvent: "adviceJobsBannerViewed",
            eventProps
        };

        const dataProps = getTrackingPropsAsDataAttrs(trackingProps);
        const { oskarClick, oskarView, oskarProps } = dataProps;

        jobsSidebarElement.setAttribute("data-oskar-view", oskarView);
        jobsSidebarElement.setAttribute("data-oskar-props", oskarProps);

        render(<JobsBanner location={location} />, jobsSidebarElement, () => {
            const ctaButtons = Array.from(jobsSidebarElement.getElementsByClassName("mfe-square-btn")) as HTMLElement[];
            ctaButtons.forEach((elem, idx, ctaButtons) => {
                eventProps.sp_click_button_text = elem.innerText.toLowerCase();
                eventProps.sp_click_insert_type = "hot_jobs";
                const trackingProps = {
                    clickEvent: "adviceJobsBannerClicked",
                    viewEvent: "adviceJobsBannerViewed",
                    eventProps
                };

                elem.setAttribute("target", "blank");
                elem.setAttribute("data-oskar-click", oskarClick);
                elem.setAttribute("data-oskar-props", JSON.stringify(trackingProps.eventProps));
            });
            pushDataLayerEvent("adviceJobsBannerLoaded", trackingProps.eventProps);
        });
    }
};
