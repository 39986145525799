import { isBrowser } from "@util/isBrowser";

export const cookieNames = {
    AUTH_ONBOARDING_COMPLETED: "__tm_ph_onboarding_completed",
    AUTH_PAGE: "__tm_auth_page",
    AUTH_SOURCE: "__tm_auth_source",
    AUTH_LOGGED_IN: "logged_in"
};

export function getCookie(name: string): string {
    if (!isBrowser()) {
        return "";
    }
    const r: string[] | null = document.cookie.match(`\\b${name}=([^;]*)\\b`);
    return r ? r[1] : "";
}

export function setCookie(name: string, value: string, days: number = 0): string {
    if (typeof document !== "object") {
        return "";
    }
    let expires = "";
    let path = "; path=/";
    if (days) {
        const date: Date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = `; expires=${date.toUTCString()}`;
    }

    document.cookie = `${name}=${value}${expires}${path}`;
    return document.cookie;
}

export function deleteCookie(name: string): void {
    setCookie(name, "", -1);
}
