interface ITag {
    id: number;
    legacyId?: string;
    primaryTopic?: string;
    slug: string;
    status: string;
    tags?: ITag[];
    title: string;
    uri?: string;
    url?: string;
}

/**
 * Check if a specific tag exists by its slug.
 */
const hasTag = (tags: ITag[], slug: string) => !!tags.filter((tag: ITag) => slug === tag.slug).length;

/**
 * Return an array of slugs from an array of tags.
 */
const getSlugs = (tags: ITag[]) => (tags && tags.map((tag: ITag) => tag.slug)) || [];

export { getSlugs, hasTag, ITag };
