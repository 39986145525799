import { IConfig } from "@server/server";
import { appendJWPlayerScripts, getJWPlayerVideoInfo, initJWPlayerTracking, initJWPlayerVideo } from "@util/jwplayer";
import { formatLog } from "@util/logging";
import { getTrackingPropsAsDataAttrs } from "@util/tracking";

const initVideoContainer = async (container: Element, generateSEOMetadata: boolean = false, templateName: string) => {
    const mediaID = container.id.split("-")[1];
    // if we are inside the hero container we auto play.
    const autostart = !!container.closest(".article-image-hero, .post-header__video-player");
    let position = "post_individual_body";
    if (autostart) {
        position = "post_individual_header";
    }
    let videoName;
    try {
        const response = await getJWPlayerVideoInfo(mediaID);
        const videoInfo = response.data;
        videoName = videoInfo.title;

        // We will use this to hook
        // into tracking down the line
        const playerInstance = initJWPlayerVideo({
            videoInfo,
            autostart,
            container,
            mute: !!autostart,
            generateSEOMetadata
        });
        initJWPlayerTracking(playerInstance, videoInfo, position);
    } catch (error) {
        const msg = `Advice-renderer could not find JWPlayer video with mediaId ${mediaID}`;
        console.error(msg, JSON.stringify(formatLog({ error })));
    }

    const eventProps = {
        sp_impression_component: "insert",
        sp_impression_insert_type: "media",
        sp_impression_position: templateName === "article" ? "in_article" : "null" as null,
        sp_media_type: "video",
        sp_media_id: mediaID,
        sp_media_name: videoName || null,
        sp_media_provider_key: mediaID || null,
        sp_media_provider_name: "jwplayer",

    };
    const trackingProps = {
        viewEvent: "videoViewed",
        eventProps
    };
    const dataProps = getTrackingPropsAsDataAttrs(trackingProps);
    const { oskarView, oskarProps } = dataProps;

    container.parentElement.setAttribute("data-video-view", oskarView);
    container.parentElement.setAttribute("data-oskar-props", oskarProps);
};

const initJWPlayerVideos = (jwPlayerID: string, config: IConfig = null, templateName: string = null,) => {
    const videoContainers = Array.from(document.getElementsByClassName("jwplayer-container"));

    if (videoContainers.length) {
        appendJWPlayerScripts(jwPlayerID, () => {
            // eslint-disable-next-line no-use-before-define
            videoContainers.forEach((container) => {
                // eslint-disable-next-line no-use-before-define
                JWPlayerVideos.initVideoContainer(container, config?.generateSEOMetadata, templateName);
            });
        });
    }
};

export const JWPlayerVideos = {
    initVideoContainer,
    initJWPlayerVideos
};
