/* eslint-disable no-bitwise */

// This isn't a very good uuid function, and wouldn't be good for use in a database for example.
// However, for the purposes of this application it only needs to be unique for a
// number of tracked elements on the page which I believe this is sufficiant for.

export function uuid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, char => {
        const r = (Math.random() * 16) | 0;
        const v = char === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}
