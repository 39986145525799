import React, { useState } from "react";
import { UseComboboxStateChange } from "downshift";

import { ComposableHeaderDropdown, IndexedObject, Search } from "@themuse/design-system";
import { fetchCompanySuggestions, CompanySuggestion } from "@util/api";
import { isBrowser } from "@util/isBrowser";
import { pushDataLayerEvent } from "@util/tracking";
import { uuid } from "@util/uuid";
import { triggerSnowplowSearchEvent, SnowplowSearchEventProps } from "@themuse/snowplow-js-client";

import adviceHeaderStyles from "./AdviceHeaderSearchDropdown.module.scss";

export const formatCompanySuggestions = (companySuggestions: CompanySuggestion[]) =>
    companySuggestions.slice(0, 4).map((companyResult: CompanySuggestion) => {
        const { text, value } = companyResult;
        const { short_name } = value;
        return {
            label: `${text}`,
            value: `/profiles/${short_name}?_search_id=${uuid()}`
        };
});

export const updateInput = async (
    changes: UseComboboxStateChange<IndexedObject>,
    setItems: (items: IndexedObject[]) => void,
    setInputValue: (inputValue: string) => void,
    fetchData:(apiBase: string, inputValue: string) => Promise<CompanySuggestion[]>
) => {
    const { inputValue } = changes;
    const jobSearchItem = {
        label: `${inputValue}`,
        value: `/search/keyword/${inputValue}/?_search_id=${uuid()}`,
    };
    setInputValue(inputValue);
    if (inputValue) {
        setItems([jobSearchItem]);
        const companyResults = await fetchData("", inputValue);
        const companyList = companyResults?.length ? formatCompanySuggestions(companyResults) : [];
        setItems([jobSearchItem].concat(companyList));
    } else {
        setItems([]);
    }
};

interface SearchDropdownProps {
    pageLocation: string;
}

export const SearchDropdown = (props: SearchDropdownProps) => {
    const { pageLocation } = props;
    const [dropdownItems, setDropdownItems] = useState([] as IndexedObject[]);
    const [inputValue, setInputValue] = useState("");

    const snowplowSearchEvent = (selectedItem: IndexedObject) => {
        const searchType = selectedItem?.value.startsWith("/profiles/") ? "company" : "job";
        // Note: Using location.origin because museApiOptions is not populated on error pages.
        const parsedUrl = new URL(selectedItem?.value || "", window.location.origin);
        const searchId = parsedUrl.searchParams.get("_search_id") || uuid();
        const eventProps: SnowplowSearchEventProps = {
            sp_search_type: searchType,
            sp_search_id: searchId,
            sp_search_keyword: selectedItem?.label
        };
        triggerSnowplowSearchEvent(eventProps);
    };

    const searchQuerySubmittedEvent = (selectedItemText: string) => {
        const searchTerm = selectedItemText ? selectedItemText : inputValue;
        const eventProps = {
            search_term: searchTerm || ""
        };
        pushDataLayerEvent("searchQuerySubmitted", eventProps);
    };

    const onKeyboardSelectItem = (_: React.KeyboardEvent<HTMLInputElement>, selectedItem: IndexedObject) => {
        if (!isBrowser()) return "";

        const searchPath = inputValue
            ? `/search/keyword/${inputValue}`
            : "/search";

        const path = selectedItem?.value
            ? selectedItem?.value
            : `${searchPath}?_search_id=${uuid()}`;

        searchQuerySubmittedEvent(selectedItem?.label);

        if (selectedItem?.value) {
            snowplowSearchEvent(selectedItem);
        } else {
            snowplowSearchEvent({
                label: inputValue,
                value: path
            });
        }

        return window.open(path, "_blank").focus();
    };

    const onMouseSelectItem = (_: React.MouseEvent<HTMLInputElement>, selectedItem: IndexedObject) => {
        searchQuerySubmittedEvent(selectedItem.label);
        snowplowSearchEvent(selectedItem);
    };

    const adviceClasses = {
        container: `${adviceHeaderStyles.container}`,
        containerOpened: `${adviceHeaderStyles.containerOpened}`,
        inputContainer: adviceHeaderStyles.inputContainer,
        inputContainerOpened: adviceHeaderStyles.inputContainerOpened,
        input: adviceHeaderStyles.input,
        menuOpened: adviceHeaderStyles.menuOpened,
        menuList: adviceHeaderStyles.menuList,
        listItem: adviceHeaderStyles.listItem,
        listItemText: adviceHeaderStyles.listItemText,
        closeButton: adviceHeaderStyles.closeButton,
        inputClearButton: adviceHeaderStyles.inputClearButton,
        clearButton: adviceHeaderStyles.clearButton,
        postLabel: adviceHeaderStyles.postLabel,
    };

    const dropdownProps = {
        classes: adviceClasses,
        dropdownProps: {
            IconComponent: (<div><Search /></div>),
            debounceWait: 10,
            dropdownList: dropdownItems,
            inputId: "search-input",
            onInputChange: (changes: UseComboboxStateChange<IndexedObject>) => updateInput(
                changes,
                setDropdownItems,
                setInputValue,
                fetchCompanySuggestions
            ),
            noResultsMessage: " ",
            onKeyboardSelectItem,
            onMouseSelectItem
        }
    };

    return <ComposableHeaderDropdown {...dropdownProps} />;
};
