import axios, { AxiosError } from "axios";
import Koa from "koa";

interface MappableObject {
    [key: string]: string | number | MappableObject;
}

interface Map {
    [key: string]: string | Map;
}

interface ContextToLogMap {
    [key: string]: { [key: string]: string }
}

interface MappedContext extends MappableObject {
    http: {
        url?: string;
        status_code?: number; /* eslint-disable-line camelcase */
        method?: string;
        referer?: string;
        useragent?: string;
    }
}

interface FormattableLog {
    context?: Koa.Context;
    contextToLogMap?: ContextToLogMap;
    error: Error;
}

export const axiosErrorToLogMap = {
    http: {
        url: "config.url",
        status_code: "response.status",
        method: "request.method"
    }
};

const LOG_STATUS = {
    INFO: "info",
    WARN: "warn",
    ERROR: "error"
};

/**
 * Formats the message key for the log e.g. HTTP GET /foo/bar
 * @example
 * const message = formatMessage("GET", "foo/bar");
 * console.log(message);
 */
const formatMessage = (method: string, url: string): string => `HTTP ${method} ${url}`;

/**
 * Returns the log level string based on the status code
 * @example
 * const logLevel = getLogLevel(500);
 * console.log(logLevel);
 */
const getLogLevel = (statusCode = 200, defaultLevel = LOG_STATUS.INFO) => {
    switch (Math.floor(statusCode / 100)) {
    case 5:
        return LOG_STATUS.ERROR;
    case 4:
        return LOG_STATUS.WARN;
    default:
        return defaultLevel;
    }
};

/**
 * Gets a nested value from on object via a string reference
 * @example
 * const nestedObject = { foo: { bar: 7 } };
 * const nestedValue = getNested(nestedObject, "foo.bar");
 * console.log(nestedValue);
 */
const getNested = (theObject: MappableObject | AxiosError, path: string, separator = ".") => {
    try {
        return path
            .replace("[", separator)
            .replace("]", "")
            .split(separator)
            .reduce((obj: MappableObject, property: string) => {
                return obj[property] as MappableObject;
            }, theObject);
    } catch (err) {
        return undefined;
    }
};
/**
 * Creates a new object using the values in mappedObject and the
 * key references in the map. Essentially an object reducer.
 * @example
 * const mappableObject = { foo: { bar: 7 } };
 * const map = { baz: "foo.bar" };
 * const mappedObject = mappedObject(mappableObject, map);
 * console.log(mappedObject);
 */
const mapObj = (mappedObject: MappableObject | AxiosError, map: Map): MappedContext => {
    const newObject: MappableObject = {};

    // Traverse the object
    Object.keys(map).forEach((key: string) => {
        let newObjectValue;
        if (typeof map[key] === "object") {
            newObjectValue = mapObj(mappedObject as MappableObject, map[key] as Map);
        } else {
            newObjectValue = getNested({ ...mappedObject }, map[key] as string);
        }
        newObject[key] = newObjectValue as MappableObject;
    });
    return newObject as MappedContext;
};

/**
 * Uses values from a Koa context object and an optional error object
 * to create a formatted log object. Uses mapObj to translate to the
 * desired format. Takes an optional mapping argument to override the default.
 * @example
 * const formattedLog = formatLog(context, error);
 * console.log(formattedLog);
 */
export const formatLog = (format: FormattableLog): MappableObject => {
    const { context, error, contextToLogMap = axiosErrorToLogMap } = format;
    let contextValues = null;
    let logInfo = null;

    if (axios.isAxiosError(error)) {
        contextValues = mapObj({ ...error }, { ...contextToLogMap });
        logInfo = {
            ...contextValues,
            level: getLogLevel(contextValues?.http?.status_code || error?.response?.status),
            message: formatMessage(contextValues.http.method || error.message, contextValues.http.url)
        };
    } else {
        const {
            req: {
                method = "",
                url = ""
            } = {},
            res: {
                statusCode = 0
            } = {}
        } = context || {};
        contextValues = mapObj({ ...context as MappableObject }, { ...contextToLogMap });
        logInfo = {
            ...contextValues,
            level: getLogLevel(statusCode),
            message: formatMessage(method, url)
        };
    }

    const errorValues = error
        ? {
            message: error.message,
            stack: error.stack,
            kind: error.name
        }
        : null;

    return {
        ...logInfo,
        ...(errorValues && { error: errorValues })
    };
};
