import React from "react";

import { isBrowser } from "@util/isBrowser";

const useWindowSize = (): { width: number; height: number } => {
    if (!isBrowser()) {
        return {
            width: 1350,
            height: 660
        };
    }
    const [windowSize, setWindowSize] = React.useState(() => ({
        width: window.innerWidth,
        height: window.innerHeight
    }));

    React.useEffect(() => {
        const onResize = () =>
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        window.addEventListener("resize", onResize);
        return () => window.removeEventListener("resize", onResize);
    });

    return windowSize;
};

export { useWindowSize };
