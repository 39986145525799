import { FIXED_NAV_TABLET_HEIGHT, SMALL_SCREEN_WIDTH_MIN } from "@advice/components/constants";
import { Location } from "@advice/hooks/Location";
import { User } from "@advice/hooks/User";
import { useWindowSize } from "@advice/hooks/windowSize";
import { Header, HeaderProps } from "@themuse/muse-front-end/dist/components/header/component";
import { get } from "@util/object";
import { pushDataLayerEvent } from "@util/tracking";
import React from "react";

import { isBrowser } from "@util/isBrowser";

interface pageHeaderProps {
    searchEnabled?: boolean;
}

const PageHeader: React.FC<pageHeaderProps> = (props) => {
    const user = React.useContext(User);
    const location = React.useContext(Location);

    // We cannot pull the hash value as it is never actually sent to the server.
    const encodedURL = encodeURIComponent(location.pathname + location.search);
    const [searchEnabled, setSearchEnabled] = React.useState<true | false>(props.searchEnabled || true);
    const [lastScrollPos, setLastScrollPos] = React.useState(0);
    const companyName = get(user?.company, "short_name");
    const userCompanySettingsUrl = companyName ? `/clients/${companyName}` : null;
    const userName = user?.cropped_name || "Guest";
    const onSearchSubmit = (searchUrl: string, searchString: string) => {
        const eventProps = {
            search_term: searchString
        };

        pushDataLayerEvent("searchQuerySubmitted", eventProps);
        pushDataLayerEvent("headerLinkClicked", {});

        window.location.assign(searchUrl + "?" + `keyword=${searchString}`);
    };

    React.useEffect(() => {
        if (
            /* flags.ldExperimentHideSearchBar === false &&
            remove the above line after ldExperimentHideSearchBar experiment */
            isBrowser() && window.innerWidth < SMALL_SCREEN_WIDTH_MIN) {
            let timer: any;

            const handleScroll = () => {
                if (timer) {
                    window.clearTimeout(timer);
                }
                timer = window.setTimeout(() => {
                    if (window.scrollY > FIXED_NAV_TABLET_HEIGHT && window.scrollY > lastScrollPos && searchEnabled) {
                        setSearchEnabled(false);
                    }
                    if (window.scrollY < lastScrollPos && searchEnabled === false) {
                        setSearchEnabled(true);
                    }
                    setLastScrollPos(window.scrollY);
                }, 150);
            };
            window.addEventListener("scroll", handleScroll, { passive: true });
            return () => window.removeEventListener("scroll", handleScroll);
        }
        return () => {};
    // }, [lastScrollPos, searchEnabled, flags]);
    }, [lastScrollPos, searchEnabled]);

    let headerProps: HeaderProps = {
        activePath: "/advice",
        searchEnabled,
        fixed: false,
        onSearchSubmit,
        signInUrl: `/user/login?next=${encodedURL}`,
        screenWidth: useWindowSize().width,
        userAuthed: user?.logged_in,
        userCompanySettingsUrl,
        userName,
        viewBookings: user?.view_bookings,
        mainContainerId: "main-content"
    };

    return <Header {...headerProps} />;
};

export { PageHeader };
