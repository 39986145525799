/**
 * Builds class names for a bem component with modifiers:
 *
 * @example
 * bemComponentClasses("some-component", "active", "large")
 * outputs "some-component some-component--active some-component--large"
 */
export function bemComponentClasses(componentName: string, ...modifiers: Array<string | null | undefined>): string {
    const modifierClasses = modifiers
        .filter((modifier: string | null) => typeof modifier === "string")
        .map((modifier: string) => `${componentName}--${modifier}`);
    return [componentName, ...modifierClasses].join(" ");
}

/**
 * Test if a string contains a valid E-Mail.
 */
export function isEmail(address: string): boolean {
    // The regex used in html5 form validation for emails
    const regex = /^[\w0-9.!#$%&’*+/=?^_`{|}~-]+@[\w0-9-]+(?:\.[\w0-9-]+)*$/i;
    return regex.test(address);
}

export function camelToSnakeCase(text: string): string {
    return text
        .replace(/(.)([A-Z][a-z]+)/, "$1_$2")
        .replace(/([a-z0-9])([A-Z])/, "$1_$2")
        .toLowerCase();
}

/**
 * Converts an id number to a string
 */
export const getId = (id: any) => (id ? parseInt(id, 10) : undefined);

/**
 * Joins a list of class names, filtering out empty / undefined values
 * ```
 * joinClassNames("class-one", null, undefined, "class-two") -> "class-one class-two"
 * ```
 */
// eslint-disable-next-line max-len
export const joinClassNames = (...classNameArr: Array<string | null | undefined>): string =>
    classNameArr.filter(Boolean).join(" ");

export const isTruthy = (string: string) => /^(true|1)$/.test(string.toLowerCase());
