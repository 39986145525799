import React, { ReactDOM } from "react";
import { render } from "react-dom";

import JobQuizContainer, { JobQuizContainerProps } from "@themuse/design-system/lib/components/JobQuiz/JobQuizContainer";
import { IExperimentAB } from "@util/experiment";

import QuizConfig from "../../util/jobQuizConfig.json";

export interface ExperimentWindow extends Window {
    experiment: IExperimentAB;
}

declare const window: ExperimentWindow;

const getQuizLocationElement = (isLegacy: boolean, isListicle: boolean): HTMLDivElement => {
    // The outer block is constant across all articles.
    const contentWell = ".article-body__content-module";
    // Legacy and Modern content diverge here
    const contentWrapper = isLegacy ? ".article-body__content-module--html" : "div";
    // A listicle will have slightly different wrapper blocks.  We want to not be after their titles, but before.
    const listicleTerminus = isLegacy ? "h2" : ".listicle-header";
    // Articles are always P tags.
    const articleTerminus = "p";
    const terminus = isListicle ? listicleTerminus : articleTerminus;

    const selectors = [
        `${contentWell} > ${contentWrapper} ${terminus}:nth-of-type(2)`,
        `${contentWell} > ${contentWrapper}`,
        ".article-body_lastUpdated-display"
    ];

    let element = null;
    for (let i = 0; i < selectors.length; i++) {
        element = document.querySelector(selectors[i]);

        if (element) {
            break;
        }
    }

    const containerElement = document.createElement("div");
    containerElement.setAttribute("id", "job-quiz");
    element.after(containerElement);

    return containerElement as HTMLDivElement;
};

const onQuizSubmit = (responses: any) => {
    const filtersFromResponses: any = [];
    responses.questions.forEach((elem: any) => {
        let answers = "";
        if (elem.response === null) {
            return;
        }

        if (Array.isArray(elem.response)) {
            answers = elem.response.join();
        } else {
            answers = elem.response;
        }
        filtersFromResponses.push(`${elem.field}=${answers}`);
    });
    const searchUrl = `/search?${filtersFromResponses.join("&")}`;
    window.open(searchUrl, "_blank");
};

export const initJobQuiz = async () => {
    const jobQuizProps: JobQuizContainerProps = {
        ...QuizConfig,
        trackingOverrides: {
            sp_impression_component: "insert",
            sp_impression_insert_type: "job_quiz",
            sp_impression_position: "second_para",
            sp_click_component: "button",
            sp_click_insert_type: "job_quiz",
            sp_click_position: "second_para"
        },
        onSubmit: onQuizSubmit
    };
    const dataLayer = window.dataLayer[0];

    const isLegacy = dataLayer.legacy_id ? true : false;
    const isListicle = dataLayer.cms_post_type === "listicle" ? true : false;
    const element = getQuizLocationElement(isLegacy, isListicle);

    render(
        <>
            <JobQuizContainer
                {...jobQuizProps}
                data-oskar-view={JSON.stringify({
                sp_impression_component: "insert",
                sp_impression_insert_type: "job_quiz",
                sp_impression_position: "second_para"
            })}
            />
        </>,
        element
    );
};
