import { IExperimentAB } from "@util/experiment";

export interface ExperimentWindow extends Window {
    google_tag_manager: any;
    experiment: IExperimentAB;
}

declare const window: ExperimentWindow;

export const initExperiment = async () => {
    const dataLayer = window.dataLayer[0];
    const isArticleOrListicle = ["article", "listicle"].includes(dataLayer.cms_post_type);
    const nonSponsoredArticle = isArticleOrListicle && !dataLayer.is_sponsored;

    if (nonSponsoredArticle) {
        window.experiment.assign();
    }
};
