import axios, { AxiosRequestConfig } from "axios";

import { IMuseApiOptions } from "@server/server";
import { get } from "@util/object";
import { formatLog } from "./logging";

export interface IApiProps {
    articlesApiHost: string;
    museApiOptions: IMuseApiOptions;
    jobSearchApiHost: string;
}

interface IApiResult {
    data: any;
    meta?: any;
}

interface IFilterArgs {
    [key: string]: string | number;
}

export type IApi = (
    props: IApiProps
) => {
    [key: string]: {
        [key: string]: {
            [key: string]: any;
        };
    };
};

export interface CompanyValue {
    object: "company_name_suggestion",
    short_name: string
}

export interface CompanySuggestion {
    object: "suggestion",
    type: "company_name",
    text: string
    value: CompanyValue,
    image: string
}

export interface CompanySuggestorResults {
    object: "suggestions",
    data: CompanySuggestion[]
}

// Max length for job-search suggestion api input
export const SUGGESTION_INPUT_MAX_LENGTH = 70;

const filterRequest = async (
    endpoint: string,
    filterBy: IFilterArgs,
    axiosOptions?: AxiosRequestConfig
): Promise<any> => {
    const response = await axios.get(endpoint, {
        params: filterBy,
        ...axiosOptions
    });
    return response;
};

/**
 * Retrieves the user object from themuse based on secure cookies.
 * @returns User
 */
export const getCurrentUser = () => {
    return axios.get("/api/user");
};

/**
 * @param  {string} apiHost - API Host for retrieving jobs for a company
 * @param  {string} companyName - Company Name
 * @param  {number} page        - Page interval to retrieve
 * @param  {number} itemsPerPage - Items returned per page
 */
const getJobsForCompany = async (apiHost: string, companyName: string, page: number, itemsPerPage?: number) => {
    if (!companyName) {
        return { data: [] };
    }
    const company = encodeURIComponent(companyName);
    const path = "/v1/jobs/search-sponsored-jobs-module";
    const query = `items_per_page=${itemsPerPage}&page=${page}&company=${company}`;
    const response = await axios.get(`${apiHost}${path}?${query}`);
    return {
        data: get(response, "data.results")
    };
};

const getPosts = async (articlesApiHost: string, filterArgs: IFilterArgs, axiosOptions?: AxiosRequestConfig) => {
    const response = await filterRequest(`${articlesApiHost}/api/posts`, filterArgs, axiosOptions || {});

    return {
        data: get(response, "data.data"),
        meta: response.data.metadata
    };
};

const getOptimizedPosts = async (articlesApiHost: string, filterArgs: IFilterArgs) => {
    const { uri, token, preview } = filterArgs;
    const query = token && preview ? `?x-craft-live-preview=${preview}&token=${token}` : "";
    const response = await axios.get(`${articlesApiHost}/api/${uri}${query}`);
    return {
        data: get(response, "data.data"),
        meta: get(response, "data.metadata")
    };
};

const getOptimizedTiles = async (articlesApiHost: string, filterArgs: IFilterArgs) => {
    const { uri, token, preview } = filterArgs;
    const query = token && preview ? `?x-craft-live-preview=${preview}&token=${token}` : "";
    const response = await axios.get(`${articlesApiHost}${uri}${query}`, {});

    return {
        pagination: get(response, "data.pagination"),
        data: get(response, "data.articles"),
        meta: get(response, "data.metadata")
    };
};

const getPost = async (articlesApiHost: string, filterArgs: IFilterArgs, axiosOptions?: AxiosRequestConfig) => {
    const { data, meta } = await getPosts(articlesApiHost, filterArgs, axiosOptions);
    return { data, meta };
};

const getOptimizedPost = async (articlesApiHost: string, filterArgs: IFilterArgs) => {
    const { data, meta } = await getOptimizedPosts(articlesApiHost, filterArgs);
    return { data, meta };
};

const getOptimizedTile = async (articlesApiHost: string, filterArgs: IFilterArgs) => {
    const { pagination, data, meta } = await getOptimizedTiles(articlesApiHost, filterArgs);
    return { pagination, data, meta };
};

const getMuseApiAuthOptions = (authOptions: IMuseApiOptions) => {
    return authOptions.authEnabled
        ? {
              auth: {
                  username: authOptions.username,
                  password: authOptions.password
              }
          }
        : {};
};

/**
 * Request company suggestions.
 */
 export const fetchCompanySuggestions = async (apiBase: string, inputValue: string, axiosOptions?: AxiosRequestConfig) => {
    const path = `${apiBase}/company-suggestion/${inputValue}`;
    try {
        const response = await axios(path, axiosOptions);
        return response.data;
    } catch (error) {
        const msg = `Advice-renderer was unable to request company suggestions from ${path}.`;
        console.error(msg, JSON.stringify(formatLog({ error })));
        return null;
    }
};

/**
 * Request company suggestions from the backend API.
 */
const fetchApiCompanySuggestions =
    async (jobSearchApiHost: string, query: string, axiosOptions?: AxiosRequestConfig): Promise<CompanySuggestorResults> => {
    // if query exists we will pass it in the params.
    const queryObj = query ? { query: query.substring(0, SUGGESTION_INPUT_MAX_LENGTH) } : {};
    const config = { ...axiosOptions, params: { type: "company_name", ...queryObj } };
    const path = `${jobSearchApiHost}/api/v2/suggestions`;
    try {
        const response = await axios(path, config);
        return response.data;
    } catch (error) {
        const msg = `Advice-renderer was unable to request company suggestions from ${path}.`;
        console.error(msg, JSON.stringify(formatLog({ error })));
        return null;
    }
};

export const Api: IApi = (props: IApiProps) => ({
    optimizedPosts: {
        get: {
            byUri: async (uri: string, query: IMetadataObj) => {
                const { token, preview } = query;
                return getOptimizedPost(props.articlesApiHost, { uri, token, preview });
            }
        },
        getAll: {
            byUri: async (uri: string) => getOptimizedTile(props.articlesApiHost, { uri })
        }
    },
    posts: {
        get: {
            byID: async (id: number, axiosOptions?: AxiosRequestConfig): Promise<IApiResult> => {
                const response = await axios.get(`${props.articlesApiHost}/api/post/${id}`, axiosOptions || {});

                return {
                    data: get(response, "data")
                };
            },
            bySlug: async (slug: string, axiosOptions?: AxiosRequestConfig) =>
                getPost(props.articlesApiHost, { slug }, axiosOptions || {}),
            byUri: async (uri: string, axiosOptions?: AxiosRequestConfig) =>
                getPost(props.articlesApiHost, { uri }, axiosOptions || {}),
            byParams: async (params: any, axiosOptions?: AxiosRequestConfig) =>
                getPosts(props.articlesApiHost, params, axiosOptions || {})
        },
        getAll: {
            byParams: async (paramsObjArray: Array<{ params: { [key: string]: string } }>) => {
                let responses: IMetadataObj[];
                await axios
                    .all(
                        paramsObjArray.map((paramObj: { params: { [key: string]: string } }) => {
                            return axios.get(`${props.articlesApiHost}/api/posts`, paramObj);
                        })
                    )
                    .then(async responseArr => {
                        responses = responseArr.map((response: IMetadataObj) => {
                            return {
                                meta: get(response, "data.metadata"),
                                data: get(response, "data.data")
                            };
                        });
                    });

                return responses;
            }
        }
    },
    companies: {
        get: {
            list: async (filterBy: IFilterArgs, axiosOptions?: AxiosRequestConfig): Promise<IApiResult> => {
                const authOptions = getMuseApiAuthOptions(props.museApiOptions);

                const response = await filterRequest(`${props.museApiOptions.host}/api/v1/companies`, filterBy, {
                    headers: {
                        referer: props.museApiOptions.host
                    },
                    ...authOptions,
                    ...axiosOptions
                });
                return {
                    data: response.data.results,
                    meta: {
                        page: response.data.page,
                        page_count: response.data.page_count,
                        items_per_page: response.data.items_per_page,
                        took: response.data.took,
                        timed_out: response.data.timed_out,
                        total: response.data.total
                    }
                };
            },
            byID: async (id: number, axiosOptions?: AxiosRequestConfig): Promise<IApiResult> => {
                const authOptions = getMuseApiAuthOptions(props.museApiOptions);
                const url = `${props.museApiOptions.host}/api/v1/companies/${id}`;

                const response = await axios
                    .get(url, {
                        headers: {
                            referer: props.museApiOptions.host
                        },
                        ...authOptions,
                        ...axiosOptions
                    })
                    .catch((error: Error) => {
                        const msg = `Advice-renderer was unable to fetch the company from ${url}.`;
                        console.error(msg, JSON.stringify(formatLog({ error })));
                    });
                return {
                    data: response && get(response, "data")
                };
            },
            bySlug: async (
                shortName: string,
                filterBy?: IFilterArgs,
                axiosOptions?: AxiosRequestConfig
            ): Promise<IApiResult> => {
                const response = await filterRequest(
                    `${props.articlesApiHost}/api/v1/companies/${shortName}`,
                    filterBy,
                    {
                        ...axiosOptions
                    }
                );

                return {
                    data: get(response, "data")
                };
            },
            /**
             * Retrieves a list of jobs for a company from the API @ https://jobs.stg.themuse.com/swagger/
             *
             * @param companyName - The company's full name
             */
            jobs: async (companyName: string): Promise<IApiResult> => {
                const { jobSearchApiHost } = props;
                return getJobsForCompany(jobSearchApiHost, companyName, 0, 5);
            },
            suggestions: async (suggestionString: string): Promise<IApiResult> => {
                const { jobSearchApiHost } = props;
                return fetchApiCompanySuggestions(jobSearchApiHost, suggestionString);
            }
        }
    }
});
