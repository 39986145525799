import axios from "axios";

import { pushDataLayerEvent } from "@util/tracking";

export const JWPLAYER_API_URL = "//cdn.jwplayer.com/v2/media/";

export interface JWPlaylistSource {
    width: number;
    height: number;
}

export interface VideoItem {
    duration: number;
    mediaid: string;
    sources: JWPlaylistSource[];
    tags: string; // Comma separated list of tags (from JWPlayer)
}

export interface VideoInfo {
    title: string;
    description: string;
    playlist: VideoItem[];
}

export interface IInitJWPlayerVideoProps {
    container: Element;
    mute?: boolean;
    autostart?: boolean;
    videoInfo: VideoInfo;
    generateSEOMetadata?: boolean;
}

const TRACKED_PLAYBACK_MILESTONES = [25, 50, 75, 100];

export const appendJWPlayerScripts = (jwPlayerID: string, callback?: () => void) => {
    const JWPlayerScript = document.createElement("script");
    JWPlayerScript.async = true;
    JWPlayerScript.onload = callback;
    JWPlayerScript.src = `//content.jwplatform.com/libraries/${jwPlayerID}.js`;
    document.body.appendChild(JWPlayerScript);
};

export const getJWPlayerVideoInfo = async (mediaID: string) => {
    return axios.get(`${JWPLAYER_API_URL}${mediaID}`);
};

export const initJWPlayerVideo = (props: IInitJWPlayerVideoProps): jwplayer.JWPlayer => {
    return jwplayer(props.container).setup({
        playlist: props.videoInfo.playlist,
        autostart: props.autostart,
        mute: props.mute,
        generateSEOMetadata: props.generateSEOMetadata
    });
};

export const initJWPlayerTracking = (playerInstance: IMetadataObj, videoInfo: IMetadataObj, position: string) => {
    Object.assign(window, { playerInstance });
    const config = playerInstance.getConfig();
    // only the first play is played, everything else is resumed.
    let started = false;
    const getEventProps = (): IMetadataObj => {
        const result: IMetadataObj = {
            jw_video_source: position || null,
            jw_video_id: null,
            jw_video_name: videoInfo.title || null,
            jw_video_provider_key: config.id.replace("jwplayer-", "") || null,
            jw_video_provider_name: "jwplayer",
            jw_video_description: videoInfo.description || null,
            jw_video_autoplay: config.autostart || null,
            jw_video_muted: !!playerInstance.getMute(),
            jw_video_position: jwplayer().getPosition(),
        };
        return result;
    };

    playerInstance.on("play", () => {
        const eventName = started ? "jwPlayerResumed" : "jwPlayerPlayed";
        pushDataLayerEvent(eventName, getEventProps());
        started = true;
    });

    playerInstance.on("pause", () => pushDataLayerEvent("jwPlayedPaused", getEventProps()));

    playerInstance.on("mute", (event: IMetadataObj) => {
        if (event.mute) {
            pushDataLayerEvent("jwPlayerMuted", getEventProps());
        } else {
            pushDataLayerEvent("jwPlayerUnmuted", getEventProps());
        }
    });

    playerInstance.on("fullscreen", () => {
        const fullscreen = playerInstance.getFullscreen();
        const props = {
            ...getEventProps(),
            jw_video_fullscreen: fullscreen,
            interaction_type: fullscreen ? "enter fullscreen" : "exit fullscreen"
        };
        pushDataLayerEvent("jwPlayerInteraction", props);
    });

    playerInstance.on("autostartNotAllowed", () => pushDataLayerEvent("jwPlayerAutostartNotAllowed", getEventProps()));

    playerInstance.on("seek", (event: IMetadataObj) => {
        const props = {
            jw_video_seek_begin: event.position,
            jw_video_seek_end: event.offset,
            ...getEventProps(),
            interaction_type: event.position > event.offset ? "rewind" : "forward"
        };
        pushDataLayerEvent("jwPlayerInteraction", props);
    });

    playerInstance.on("time", (event: IMetadataObj) => {
        const instance: IMetadataObj & { latestPercentComplete?: number } = playerInstance;
        const videoPercentComplete = (event.position / event.duration) * 100;
        TRACKED_PLAYBACK_MILESTONES.forEach(milestone => {
            const latestSeen = instance.latestPercentComplete || 0;
            if (videoPercentComplete >= milestone && milestone >= latestSeen) {
                pushDataLayerEvent("jwPlayerPlaybackMilestoneReached", {
                    video_percent_complete: milestone,
                    ...getEventProps()
                });
            }
        });
        instance.latestPercentComplete = videoPercentComplete;
    });
};
