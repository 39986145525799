import "intersection-observer";

interface IObserverOptions {
    root?: NodeListOf<HTMLElement>;
    rootMargin: string;
    threshold: number;
}

type IObserverCallBack = (element: HTMLElement) => void;

/**
 * Creates an intersection observer.
 * At minimum you need a callback function to fire on each element as it enters the viewport.
 * You can pass in optional object with threshold and margin.
 */
const getObserver = (cb: IObserverCallBack, options?: IObserverOptions): IntersectionObserver => {
    const OBSERVER_OPTIONS = {
        rootMargin: "0px",
        threshold: 1.0,
        ...options
    };

    const observer = new IntersectionObserver(entries => {
        entries.forEach((entry: any) => {
            if (entry.isIntersecting) {
                const element = entry.target;
                if (!entry.isIntersecting || !(element instanceof HTMLElement)) {
                    return;
                }
                cb(element);
                observer.unobserve(element);
            }
        }, OBSERVER_OPTIONS);
    });
    return observer;
};

export { getObserver };
