import { useState } from "react";

export function useLocalStorage(key: string): [string, (value: string) => void] {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState(() => {
        // Get from local storage by key
        const item = window.localStorage.getItem(key);
        return item ? item : "";
    });
    // Return a wrapped version of useState's setter function that
    // persists the new value to localStorage.
    const setValue = (value: string) => {
        // Save state
        setStoredValue(value);
        // Save to local storage
        window.localStorage.setItem(key, value);
    };
    return [storedValue, setValue];
}
