import { getObserver } from "@util/Observer";

const setProps = (image: HTMLImageElement) => {
    const deferSrc = image.getAttribute("defer-src");
    if (image.dataset.src) {
        image.setAttribute("src", image.dataset.src);
    }
    if (deferSrc) {
        image.setAttribute("src", deferSrc);
    }
    if (image.dataset.srcset) {
        image.setAttribute("srcSet", image.dataset.srcset);
    }
};

const setFade = (image: HTMLImageElement) => {
    image.classList.add("loaded");
};

const initLazyImages = () => {
    const lazyLoadImages = document.querySelectorAll(
        "img[loading='lazy']:not([src]), img[defer-src]:not([src]), img[data-src]:not([src])"
    );
    const lazyNotDeferred = document.querySelectorAll("img[loading='lazy']");
    Array.from(lazyLoadImages).forEach((el: HTMLImageElement) => {
        const width = parseInt(el.getAttribute("width"), 10) || el.parentElement.offsetWidth;

        // our most common image aspect ratio is 2/1 so if all else fails, use it.
        const height = parseInt(el.getAttribute("height"), 10) || width / 2;
        // Inject an SVG to hold the image open at the correct size.
        // This prevents the page from thrashing as it scrolls.
        // eslint-disable-next-line max-len
        const placeHolder = `data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${width} ${height}"%3E%3C/svg%3E`;
        if (height && width) {
            el.src = placeHolder;
        }
    });

    const imageObserver = getObserver(setProps);
    const imageLoadObserver = getObserver(setFade);
    lazyLoadImages.forEach(image => {
        imageObserver.observe(image);
    });
    lazyNotDeferred.forEach(image => {
        imageLoadObserver.observe(image);
    });
};

export { initLazyImages, getObserver };
