import Axios from "axios";

import { EXTRA_SMALL_SCREEN_WIDTH_MAX, MEDIUM_SCREEN_WIDTH_MAX } from "@advice/components/constants";
import { getAllowedLocationFromDMA, translateDMAString } from "@util/location";
import { getTrackingPropsAsDataAttrs } from "@util/tracking";
import { IExperimentAB } from "@util/experiment";
import { formatLog } from "@util/logging";

export interface ExperimentWindow extends Window {
    experiment: IExperimentAB;
}

declare const window: ExperimentWindow;

const getDMAFromCarmenAPI = async () => {
    // DMA: Designated Market Area
    const sessionStorageDMA = window.sessionStorage.getItem("carmen_dma");
    if (sessionStorageDMA) {
        return sessionStorageDMA;
    }
    const { data } = await Axios.get("https://carmen.themuse.com/me");
    if (data.dma) {
        window.sessionStorage.setItem("carmen_dma", data.dma);
    }
    return data.dma;
};

/**
 * Injects the Growform at the most advantageous position.
 *
 * @param isLegacy {boolean} Returned by the API.
 * @param isListicle {boolean} Returned by the API.
 * @param frame {HTMLIFrameElement} The item to be injected.
 *
 * @returns {HTMLIFrameElement} A reference to the Item being injected.
 * */
 const injectGrowForm = (isLegacy: boolean, isListicle: boolean, frame: HTMLIFrameElement): HTMLIFrameElement => {
    // The outer block is constant across all articles
    const contentWell = ".article-body__content-module";
    // Legacy and Modern content diverge here
    const contentWrapper = isLegacy ? ".article-body__content-module--html" : "div";
    // A listicle will have slightly different wrapper blocks.  We want to not be after their titles, but before.
    const listicleTerminus = isLegacy ? "h2" : ".listicle-header";
    // Articles are always P tags.
    const articleTerminus = "p";
    const terminus = isListicle ? listicleTerminus : articleTerminus;

    const selectors = [
        `${contentWell} > ${contentWrapper} ${terminus}:nth-of-type(2)`,
        `${contentWell} > ${contentWrapper}`,
        ".article-body_lastUpdated-display"
    ];

    let element = null;
    for (let i = 0; i < selectors.length; i++) {
        element = document.querySelector(selectors[i]);

        if (element) {
            break;
        }
    }
    return element.appendChild(frame);
};

const generateGrowformFrame = async (growformUrl: string, dataLayer: any) => {
    const windowWidth = window.innerWidth;
    const isMobile = (windowWidth <= EXTRA_SMALL_SCREEN_WIDTH_MAX) ? true : false;
    let placement = "2nd para desktop";
    if (isMobile) {
        placement = "2nd para mobile";
    }

    let dma = null;
    let location = null;
    try {
        dma = await getDMAFromCarmenAPI();
        location = getAllowedLocationFromDMA(translateDMAString(dma));
    } catch (error) {
        const msg = `Advice-renderer was unable to fetch the current location from carmen within GrowForm.`;
        console.error(msg, JSON.stringify(formatLog({ error })));
    }
    const eventProps = {
        banner_type: "Job_Quiz_embed",
        click_text: "Calculate my jobs",
        dma_location: (dma) ? dma : null,
        experiments: [...dataLayer.experiments, window.experiment.getOskarFormat()],
        placement,
        source: "post-individual",
        used_location: (location) ? location : null,
        sp_impression_component: "insert",
        sp_impression_position: "second_para",
        sp_impression_insert_type: "job_quiz",
        sp_page_section: "advice",
        sp_page_type: "content"
    };

    const trackingProps = {
        viewEvent: "adviceJobsBannerViewed",
        eventProps
    };
    const dataProps = getTrackingPropsAsDataAttrs(trackingProps);
    const { oskarView, oskarProps } = dataProps;
    const pageElement = document.createElement("iframe");
    pageElement.src = growformUrl;
    pageElement.id = "growform_form";
    pageElement.style.display = "block";
    pageElement.style.border = "none";
    pageElement.style.width = "100%";
    pageElement.style.minHeight = "500px";
    pageElement.setAttribute("data-oskar-view", oskarView);
    pageElement.setAttribute("data-oskar-props", oskarProps);

    return pageElement;
};

const injectGrowFormFuncs = (element: HTMLIFrameElement) => {
    const growformFuncs = document.createElement("script");
    /* eslint-disable max-len */
    growformFuncs.innerHTML = `
        function receiveMessage(e) {
            "growform_resize_height" == e.data.action && resizeHeight(e.data.height),
            "growform_submit_form" == e.data.action && fireSubmission(e.data.customScript),
            "growform_add_parent_gtm_container" == e.data.action && addParentGTMContainer(e.data.containerId, e.data.analyticsSettings)
        }
        function resizeHeight(e) {
            e = parseInt(e);
            e = Math.round(e) + 10,
            e += "px",
            document.getElementById("growform_form").style.height = e
        }
        function addParentGTMContainer(containerId, analyticsSettings) {
            eval(function(e, t, i, a, r) {
                e[a] = e[a] || [],
                e[a].push({
                    "gtm.start": (new Date).getTime(),
                    event: "gtm.js"
                });
                var n = t.getElementsByTagName(i)[0]
                , o = t.createElement(i);
                o.async = !0,
                o.src = "https://www.googletagmanager.com/gtm.js?id=" + r,
                n.parentNode.insertBefore(o, n)
            }(window, document, "script", "dataLayer", containerId)),
            window.dataLayer = window.dataLayer || [],
            window.dataLayer.push({
                event: "growformAnalyticsSettings",
                growformAnalyticsSettings: analyticsSettings
            })
        }
        function fireSubmission(customScript) {
            var existingScript = document.getElementById("customScriptPageDiv");
            if (!existingScript) {
                var customScriptPageDiv = document.createElement("customScriptPageDiv");
                document.body.appendChild(customScriptPageDiv),
                customScriptPageDiv.innerHTML = customScript;
                for (var scripts = customScriptPageDiv.getElementsByTagName("script"), i = 0; i < scripts.length; i++)
                    eval(scripts[i].innerText)
            }
        }
        window.addEventListener("message", receiveMessage, !1);
    `;
    /* eslint-enable max-len */
    element.parentElement.appendChild(growformFuncs);
};

export const initGrowform = async () => {
    const growformUrl = `https://embed.growform.co/go/621e9be70f2c290022c834ee/embedMethod/iFrame`;
    const dataLayer = window.dataLayer[0];

    const isLegacy = dataLayer.legacy_id ? true : false;
    const isListicle = dataLayer.cms_post_type === "listicle" ? true : false;

    const growformFrame = await generateGrowformFrame(growformUrl, dataLayer);
    const injectedIframe = injectGrowForm(isLegacy, isListicle, growformFrame);
    injectGrowFormFuncs(injectedIframe);
};
