export function translateDMAString(dma: string, city = ""): string {
    // Some DMA strings need to be translated from their original string to a simpler string for display purposes.
    // Some DMA strings also contain multiple locations and we want to sometimes return an individual city instead of
    // the DMA string. Carmen separates city and state so we map directly from a city within a DMA to a city/state value

    const dmaCityTranslations: { [key: string]: { [key: string]: string } } = {
        "Albuquerque-Santa Fe, NM": { default: "Albuquerque, NM", "Sante Fe": "Sante Fe, NM" },
        "Boston, MA-Manchester, NH": { default: "Boston, MA", Manchester: "Manchester, NH" },
        "Cleveland-Akron (Canton), OH": { default: "Cleveland, OH", Akron: "Akron, OH" },
        "Dallas-Ft. Worth, TX": { default: "Dallas, TX" },
        "Greensboro-High Point-Winston Salem, NC": {
            default: "Greensboro, NC",
            "High Point": "High Point, NC",
            "Winston Salem": "Winston Salem, NC"
        },
        "Harrisburg-Lancaster-Lebanon-York, PA": { default: "Harrisburg, PA", Lancaster: "Lancaster, PA" },
        "Hartford & New Haven, CT": { default: "Hartford, CT", "New Haven": "New Haven, CT" },
        "Miami-Ft. Lauderdale, FL": { default: "Miami, FL", "Fort Lauderdale": "Fort Lauderdale, FL" },
        "Minneapolis-St. Paul, MN": { default: "Minneapolis, MN", "Saint Paul": "Saint Paul, MN" },
        "New York, NY": { default: "New York, NY", Brooklyn: "Brooklyn, NY", "Jersey City": "Jersey City, NJ" },
        "Norfolk-Portsmouth-Newport News,VA": { default: "Norfolk, VA", "Newport News": "Newport News, VA" },
        "Orlando-Daytona Beach-Melbourne, FL": { default: "Orlando, FL", "Daytona Beach": "Daytona Beach, FL" },
        "Raleigh-Durham (Fayetteville), NC": { default: "Raleigh, NC", Durham: "Durham, NC" },
        "Richmond-Petersburg, VA": { default: "Richmond, VA" },
        "Sacramento-Stockton-Modesto, CA": { default: "Sacramento, CA" },
        "San Francisco-Oakland-San Jose, CA": {
            default: "San Francisco, CA",
            Oakland: "Oakland, CA",
            "San Jose": "San Jose, CA"
        },
        "Seattle-Tacoma, WA": { default: "Seattle, WA", Tacoma: "Tacoma, WA" },
        "Tampa-St Petersburg (Sarasota), FL": { default: "Tampa, FL", "St. Petersburg": "St. Petersburg, FL" },
        "Washington, DC (Hagerstown, MD)": { default: "Washington, DC", Arlington: "Arlington, VA" }
    };

    if (dmaCityTranslations[dma]) {
        return dmaCityTranslations[dma][city] ? dmaCityTranslations[dma][city] : dmaCityTranslations[dma].default;
    }

    return dma;
}

export const getAllowedLocationFromDMA = (dma: string) => {
    const allowedDMAs = [
        "New York, NY",
        "San Francisco, CA",
        "Chicago, IL",
        "Boston, MA",
        "Washington, DC",
        "Austin, TX",
        "Denver, CO",
        "Seattle, WA",
        "Los Angeles, CA"
    ];

    return allowedDMAs.includes(dma) ? dma : null;
};
