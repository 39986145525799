/**
 * Objects with unknown keys and values.
 */
interface IMetadataObj {
    [x: string]: any;
}

const isObjectLike = (value: any) => {
    return typeof value === "object" && value !== null;
};

const getTag = (value: any) => {
    if (value === undefined) {
        return "[object Undefined]";
    }
    if (value === null) {
        return "[object Null]";
    }

    return toString.call(value);
};

/**
 * Extracts an object from an array of objects.
 * * If the array is empty returns an empty object.
 * * If the matcher finds no match returns an empty object.
 * * If no matcher is passed returns the first item in the array.
 */
const getObjectFromArray = (array: any[], key?: string, value?: any) => {
    return (Array.isArray(array) && array.find((item: any) => (key && value ? item[key] === value : item))) || {};
};

/**
 * Checks if the value is actually an object.
 */
const isObject = (value: any) => {
    if (!isObjectLike(value) || getTag(value) !== "[object Object]") {
        return false;
    }

    if (Object.getPrototypeOf(value) === null) {
        return true;
    }

    let proto = value;
    while (Object.getPrototypeOf(proto) !== null) {
        proto = Object.getPrototypeOf(proto);
    }
    return Object.getPrototypeOf(value) === proto;
};

/**
 * Gets the value deep within a path of object.
 * If at any point in the path the key does not exist. We return undefined.
 * If we have a default value and the path does not exist return that default value.
 *
 * This allows you to avoid constantly checking for properties along the way like
 * `this.state.wave && this.state.wave.data && this.state.wave.data.companyName`
 *
 * @example get(this, "state.wave.data.companyName") // can return undefined
 * @example get(this, "state.companyOptions", []); // always returns an array.
 */
function get(target: IMetadataObj, path: string, defaultValue?: any): any {
    const targetObj = isObject(target) ? target : {};
    const result = path.split(".").reduce((obj: IMetadataObj, key: string) => (obj || {})[key], targetObj);

    return [undefined, null].includes(result) ? defaultValue : result;
}

/**
 * Recursively convert undefined values to null within an object.
 * WARNING: This does not account for functions! It is meant only for tracking data for now.
 */
const nullify = (obj: IMetadataObj) => {
    // Clone the original so we do not mutate it.
    const result: IMetadataObj = { ...obj };
    Object.keys(result).forEach((key: string) => {
        if ("undefined" === typeof result[key]) {
            result[key] = null;
        }
        if (result[key] !== null && typeof result[key] === "object") {
            nullify(result[key]);
        }
    });
    return result;
};

/**
 * toQueryString: Convert an object to query string removing nulls.
 *
 * @param {IMetadataObj} obj  an object of simple values.
 * @return {string} keys and values separated by ampersands.
 * @example `foo=3&bar=2`
 */
const toQueryString = (obj: IMetadataObj) => {
    return Object.keys(obj)
        .map((key: string) => {
            return obj[key] ? key + "=" + obj[key] : "";
        })
        .filter(Boolean)
        .join("&");
};

export { get, getObjectFromArray, isObject, IMetadataObj, nullify, toQueryString };
