import { isBrowser } from "@util/isBrowser";

export const pushDataLayerEvent = (event: string, props: { [key: string]: any } = {}) => {
    if (isBrowser() && window.dataLayer) {
        window.dataLayer.push({ event, ...props });
    }
};

export const pushDataLayerVariable = (props: { [key: string]: any }) => {
    if (isBrowser() && window.dataLayer) {
        window.dataLayer.push({ ...props });
    }
};

export const getTrackingPropsAsDataAttrs = (props: { clickEvent?: string; viewEvent?: string; eventProps?: any }) => {
    const trackingProps: { [key: string]: string } = {};
    if (props.clickEvent) {
        trackingProps.oskarClick = props.clickEvent;
    }
    if (props.viewEvent) {
        trackingProps.oskarView = props.viewEvent;
    }
    trackingProps.oskarProps = JSON.stringify(props.eventProps || {});
    trackingProps.trackingProps = JSON.stringify(props.eventProps || {});
    trackingProps.trackingEventType = "impression";
    return trackingProps;
};

/**
 * Extracts Optimize experiment id and variant id from data layer
 *
 */
export const getOptimizeData = () => {
    const allExperiments: any[] = [];
    const experiments: any[] = window?.dataLayer?.filter((x: object) => x === Object(x) && "experimentID" in x) ?? [];
    experiments.forEach((experiment) => {
        allExperiments.push({
            experiment_id: experiment.experimentID,
            variant_id: experiment.variantID
        });
    });
    return allExperiments;
};

interface TrackAdsInterface {
    eventType: "clicked" | "viewed";
    creativeId: string;
    lineItemId: string;
}

/**
 * Gather a ton of data from the dataLayer then generate the correct Oskar Event with it.
 */
export const trackAds = (data: TrackAdsInterface) => {
    const [dl] = window.dataLayer;
    const { eventType, creativeId, lineItemId } = data;
    /* get all the props from the dataLayer. */
    const oskarProps = {
        advice_application_version: dl.advice_application_version,
        site_section: dl.site_section,
        legacy_id: dl.legacy_id,
        craft_id: dl.craft_id,
        cms_uri: dl.cms_uri,
        cms_slug: dl.cms_slug,
        page_title: dl.page_title,
        author_id: dl.author_id,
        author_craft_id: dl.author_craft_id,
        author_name: dl.author_name,
        experiments: dl.experiments,
        placement_position: "ad_unit_sticky_footer",
        post_id: dl.legacy_id,
        post_type: dl.cms_post_type,
        post_sub_type: dl.cms_post_sub_type,
        post_name: dl.post_name,
        post_short_name: dl.post_short_name,
        post_legacy_tags: dl.post_tags,
        post_primary_topic: dl.primary_topic,
        post_topics: dl.post_topics,
        post_locations: dl.locations,
        post_job_levels: dl.job_levels,
        post_career_types: dl.career_types,
        post_created_at: dl.post_created_at,
        post_author_name: dl.author_name,
        post_author_id: dl.author_id,
        post_author_craft_id: dl.author_craft_id,
        post_author_short_name: dl.post_author_short_name,
        post_scheduling_date: dl.post_scheduling_date,
        post_sponsor_company_id: dl.post_sponsor_company_id,
        post_sponsor_company_craft_id: dl.post_sponsor_company_craft_id,
        post_sponsor_company_name: dl.post_sponsor_company_name,
        product: dl.product
    };

    /* create an object from the Ads information. */
    const adExperimentData = {
      line_item_id: lineItemId,
      creative_id: creativeId
    };

    /* filter out the duplicates. */
    if (!oskarProps.experiments.some((x: any) => x.line_item_id === adExperimentData.line_item_id)) {
        window.dataLayer.push(adExperimentData);
        oskarProps.experiments.push(adExperimentData);
    }

    /* set the event name. */
    const eventName = eventType === "clicked"
        ? "Advice Company Content Clicked"
        : "Advice Company Content Viewed";
    /* Fire the Oskar event. */
    window.OskarTS.capture(eventName, oskarProps);
};
