import React, { useEffect, useState } from "react";
import axios from "axios";

import { getCookie } from "@util/cookie";
import { isEmail } from "@util/strings";
import { useLocalStorage } from "@advice/hooks/localStorage";

import {
    Newsletter as NewsletterModal,
    NewsletterProps
} from "@themuse/muse-front-end/dist/organisms/newsletter/component";
import { pushDataLayerEvent, pushDataLayerVariable } from "@util/tracking";
import { formatLog } from "@util/logging";

interface newsletterSubmissionValues {
    email: string
}

declare global {
    interface Window {
        google_tag_manager: any;
        dataLayer: any;
        localStorage: any;
    }
}

const newsletterSubscribeRequest = async (values: newsletterSubmissionValues) => {
    const emailSubscription = {
        email: values.email,
        page_section: "advice"
    };

    const config = {
        headers: { "X-Xsrftoken": getCookie("_xsrf") }
    };

    const apiPath = "/api/newsletter/subscribe";

    const newsletterSuccess = await axios
        .post(apiPath, emailSubscription, config)
        .then(response => {
            const { data } = response;
            if (response.status === 200 || response.status === 201) {
                // Push subscription_id to dataLayer for tracking
                if (data.subscription_id) {
                    pushDataLayerVariable({ emailSubscriptionID: data.subscription_id });
                }
                return true;
            }
            throw new Error(`Newsletter subscription was unsuccessful. Status: ${response.status}`);
        })
        .catch(error => {
            const msg = `Advice-renderer was unable to fetch the current location from carmen within Newsletter.`;
            console.error(msg, JSON.stringify(formatLog({ error })));
            return false;
        });

    return newsletterSuccess;
};

const Newsletter = () => {
    const [showNewsletter, setShowNewsletter] = useState(false);
    const [showFormModal, setShowFormModal] = useState(false);

    const [userHasSubscribed, setUserHasSubscribed] = useLocalStorage("newsletter_subscription");
    const [lastClosedForm, setLastClosedForm] = useLocalStorage("newsletter_form_closed");

    const oneDayMilliseconds = 86400000;
    const newsletterHashExists = window.location.hash === "#newsletter";
    const formNotClosedInLast24Hours = Date.now() - (Date.parse(lastClosedForm) || 0) > oneDayMilliseconds;

    useEffect(() => {
        pushDataLayerVariable({ newsletterCampaignType: "newsletter_overlay_Dec2020" });
        if ((formNotClosedInLast24Hours && !userHasSubscribed) || newsletterHashExists) {
            setShowNewsletter(true);
            let delay = 5000;
            if (newsletterHashExists) {
                delay = 500;
            }
            const timer = setTimeout(() => {
                setShowFormModal(true);
            }, delay);

            return () => clearTimeout(timer);
        }

        return () => {};
    }, []);

    useEffect(() => {
        if (showNewsletter && showFormModal) {
            setTimeout(() => {
                const input = document.querySelector(".mfe-modal input:not([type=hidden])") as HTMLInputElement | null;
                input?.focus();
            }, 1);
        }
    }, [showFormModal, showNewsletter]);

    const handleModalFormSubmit = async (formData: newsletterSubmissionValues) => {
        const newsletterSuccess = await newsletterSubscribeRequest(formData);
        const validationError = !isEmail(formData.email) ? "validation" : null;
        const errorType = !validationError && !newsletterSuccess ? "Request Failed" : null;
        pushDataLayerVariable({
            newsletterErrorType: errorType
        });
        if (!newsletterSuccess) {
            window.google_tag_manager["GTM-P3K3QB"].dataLayer.set("newsletterErrorType", errorType);
        }
        if (newsletterSuccess) {
            setUserHasSubscribed("true");
            window.google_tag_manager["GTM-P3K3QB"].dataLayer.set("newsletterErrorType", errorType);
        }
        return newsletterSuccess;
    };

    const newsletterProps: NewsletterProps = {

        handleModalFormClose: () => {
            setLastClosedForm(new Date().toISOString());
            setShowFormModal(false);
            pushDataLayerEvent("newsletterFormClosed");
        },
        handleSuccessModalClose: () => {
            pushDataLayerEvent("newsletterSuccessClosed");
        },
        handleModalFormSubmit,
        showFormModal,
        trackingProps: {
            jobAlertSuccessModal: {
                modal: {
                    trackingId: "newsletterSuccessModal"
                },
                searchButton: {
                    trackingId: "newsletterSuccessModalSearchButton"
                }
            }
        }
    };

    return showNewsletter ? <NewsletterModal {...newsletterProps} /> : null;
};

export { Newsletter, newsletterSubscribeRequest };
