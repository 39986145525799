import React from "react";

interface ILocationProps {
    href: string;
    isAMP?: boolean;
    pathname: string;
    referrer: string;
    search: string;
}

declare global {
    interface Window {
        __MUSE__: any;
    }
}

/**
 * provides both window.location.href and document.referrer as a wrapper to components.
 * @example
 * <LocationProvider>
 *     <MyComponent />
 * </LocationProvider>
 * Then inside your component:
 * @example
 * import { Location } from "@advice/components/shared/Location";
 * export const MyComponent: React.FC = () => {
 *     const location = useContext(Location);
 *     console.log("href", location.href);
 * }
 */
const Location = React.createContext(({} as any) as ILocationProps);

const LocationProvider: React.FC<ILocationProps> = props => {
    const { href, referrer, isAMP, pathname, search } = props;
    return (
        <Location.Provider
            value={{
                href,
                isAMP,
                pathname,
                referrer,
                search
            }}
        >
            {props.children}
        </Location.Provider>
    );
};

export { ILocationProps, Location, LocationProvider };
