import { AdviceWindow } from "@advice/advice";
import { initSnowplowTracker, triggerSnowplowPageViewEvent } from "@themuse/snowplow-js-client";
import { getTrackingPropsAsDataAttrs } from "@util/tracking";

declare const window: AdviceWindow;

const capitalizeStr = (str:string):string => {
    const lowerStr = str.toLowerCase();
    return str.charAt(0).toUpperCase() + lowerStr.slice(1);
};

// the following function can be deleted after the nav header text change optimize test is complete (4/7/21)
const fireOptimizeEvents = (link: any):void => {
    const linkName = link.children[0].innerText;
    const formattedLinkName = capitalizeStr(linkName);
    window?.ga("send", "event", {
        "eventAction": `${formattedLinkName} link clicked from Advice Page`,
        "eventLabel": undefined,
        "eventCategory": undefined,
        "eventValue": undefined
    });
};

const initHeaderTracking = () => {
    // ES5 doesn't allow for loops for NodeList arrays requiring this slice
    const headerLinks = [].slice.call(document.querySelectorAll("[data-header-link]"));

    for (const el of headerLinks) {
        const trackingProps = {
            clickEvent: "headerLinkClicked"
        };
        const dataProps = getTrackingPropsAsDataAttrs(trackingProps);
        const { oskarClick } = dataProps;
        el.setAttribute("data-oskar-click", oskarClick);
        el.addEventListener("click", () => { fireOptimizeEvents(el); }); // used for google optimize testing
    }
    const initialState = window.__MUSE__.initialState;
    initSnowplowTracker(initialState?.config.spCollector);
    triggerSnowplowPageViewEvent();
};

export { initHeaderTracking };
