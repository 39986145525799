import { getObserver } from "@util/Observer";
import { IExperimentAB } from "@util/experiment";
import { triggerSnowplowClickEvent } from "@themuse/snowplow-js-client";

interface AdviceWindow extends Window {
    dataLayer: IMetadataObj[];
    experiment: IExperimentAB;
    google_tag_manager: any;
}

declare const window: AdviceWindow;

const GTM_CONTAINER_ID = "GTM-P3K3QB";

/**
 * Remove Snowplow data from tracking props to prevent datalayer overrides.
 */
const removeSnowplowProps = (props: IMetadataObj) => {
    let updatedProps: IMetadataObj = {};
    Object.keys(props).forEach(key => {
        if (!key.startsWith("sp_")) {
            updatedProps[key] = props[key];
        }
    });
    return updatedProps;
};

/**
 * Get the JSON from the element and parse it.
 */
 const getEventProps = (element: HTMLElement) => {
    const props = element.dataset.oskarProps;
    return props ? removeSnowplowProps(JSON.parse(props)) : "";
};

/**
 * The function to fire for an Oskar View event.
 */
 const oskarViewTrigger = (element: HTMLElement) => {
    const event = element.dataset.oskarView || "";
    const eventProps = getEventProps(element);
    window.dataLayer.push({ event, ...eventProps });
};

/**
 * The function to fire for an Oskar click event.
 */
const oskarClickTrigger = (e: MouseEvent) => {
    const element = e.target as HTMLElement;
    const event = element.dataset.oskarClick || "";
    const eventProps = getEventProps(element);
    window.dataLayer.push({ event, ...eventProps });
};

const getExperimentData = () => {
    let experimentData = null;
    if (window.experiment) {
        experimentData = window.experiment.getOskarFormat();
    }
    if (!window?.google_tag_manager?.[GTM_CONTAINER_ID]) {
        window.dataLayer.push({ experiments: [experimentData] });
    } else {
        window.google_tag_manager[GTM_CONTAINER_ID].dataLayer.set("experiments", [experimentData]);
    }

    const oskarView = document.querySelectorAll("[data-oskar-view]");
    const oskarViewObserver = getObserver(oskarViewTrigger);
    oskarView.forEach(element => {
        oskarViewObserver.observe(element);
    });

    // This is blocking other onClick events from firing.
    // So adding onClick to react components is not working.
    document.addEventListener("click", ($evt) => {
        const target = <HTMLElement> $evt.target;
        const isTracked = target.hasAttribute("data-oskar-click");

        if (isTracked) {
            oskarClickTrigger($evt);
        }

        const el = target.closest("[data-oskar-click]") || document.body;
        const props = el.getAttribute("data-tracking-props");
        let parsedProps;

        try {
            parsedProps = JSON.parse(props);
        } catch (e) {
            console.warn(e);
        }

        if (parsedProps?.hasOwnProperty("sp_click_component")) {
            triggerSnowplowClickEvent(parsedProps);
        }
    });
};

/**
 * Initialize the Oskar events.
 */
const initOskarTriggers = async () => {
    getExperimentData();
};

export {
    getEventProps,
    initOskarTriggers,
    removeSnowplowProps
};
