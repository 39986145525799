import { JWPlayerVideos } from "@advice/apps/initJWPlayerVideos";
import { initHeader } from "@advice/apps/initHeader";
import { initEmailCapture } from "@advice/apps/initEmailCapture";
import { initJobsSidebar } from "@advice/apps/initJobsSidebar";
import { initLazyImages } from "@advice/apps/initLazyImages";
import { initOskarTriggers } from "@advice/apps/initOskarTriggers";
import { initSnowplowTracker, trackSnowplowImpressions, triggerSnowplowPageViewEvent } from "@themuse/snowplow-js-client";
import { IInitialState } from "@server/util/pageWrapper";
import { ExperimentAB, IExperimentAB } from "@util/experiment";
import { formatLog } from "@util/logging";
import { initGrowform } from "./apps/initGrowform";
import { initJobQuiz } from "./apps/initJobQuiz";
import { initExperiment } from "./apps/initExperiment";
import { TOP_TEN_PAGES } from "./components/constants";

export interface AdviceWindow extends Window {
    __MUSE__: {
        initialState: IInitialState;
    };
    dataLayer: IMetadataObj[];
    experiment: IExperimentAB;
    ga: UniversalAnalytics.ga;
}

declare const window: AdviceWindow;

document.addEventListener("DOMContentLoaded", async () => {
    const initialState = window.__MUSE__.initialState;
    initLazyImages();
    window.experiment = new ExperimentAB(initialState?.config?.experiments);
    const excludedArticles = [
        ...TOP_TEN_PAGES,
        "vibe-awards-2022-health-wellness-benefits",
        "vibe-awards-2022-professional-development-benefits",
        "vibe-awards-2022-vacation-time-off-benefits",
        "vibe-awards-2022-parental-benefits",
        "vibe-awards-2022-office-life-and-perks",
        "vibe-awards-2022-work-flexibility",
        "vibe-awards-2022-financial-retirement-benefits",
        "vibe-awards-2022-methodology"
    ];
    const excludeExperiment = excludedArticles.includes(initialState.slug);

    if (!excludeExperiment) {
        await initExperiment();
    }
    await initHeader(initialState);
    await initJobsSidebar();
    if (window.experiment.isAssignedExperiment("JOB_QUIZ_NATIVE") &&
        window.dataLayer[0].cms_post_type !== "landing" &&
        window.dataLayer[0].cms_post_type !== "companyLanding" &&
        window.dataLayer[0].cms_post_type !== "writer" &&
        !window.dataLayer[0].is_sponsored &&
        !excludeExperiment
    ) {
        if (window.experiment.isAssignedVariant("native")) {
            await initJobQuiz();
        } else {
            await initGrowform();
        }
    }
    await initOskarTriggers();
    await initEmailCapture(initialState);
    const jwPlayerID = ((initialState || {}).config || {}).jwPlayerID;
    if (jwPlayerID) {
        try {
            JWPlayerVideos.initJWPlayerVideos(jwPlayerID, initialState?.config, initialState?.templateName);
        } catch (error) {
            const msg = `Advice-renderer could not initialize JWPlayer Videos.`;
            console.error(msg, JSON.stringify(formatLog({ error })));
        }
    }

    // Note: initialState is not populated on error pages.
    if (initialState?.config) {
        trackSnowplowImpressions();
    }
});
