import React from "react";

import {
    NEWSLETTER_EXCLUDED_PAGES,
    NEWSLETTER_EXCLUDED_PRIMARY_TOPICS,
    NEWSLETTER_EXCLUDED_TAGS
} from "@advice/components/constants";
import { Newsletter } from "@advice/components/organisms/Newsletter";
import { IInitialState } from "@server/util/pageWrapper";
import { hasTag } from "@util/tags";

const EmailCapture = (props: IInitialState) => {
    const {
        primaryTopic,
        slug,
        tags,
        templateName,
    } = props;

    const shouldNewsletterShow = () => {
        switch (templateName) {
            case "companyLanding": {
                return true;
            }
            case "landing": {
                const foundSlug = NEWSLETTER_EXCLUDED_PAGES.some(x => x === slug);
                if (foundSlug) {
                    return false;
                }
                break;
            }
            case "listicle":
            case "article": {
                // check slugs
                const foundSlug = NEWSLETTER_EXCLUDED_PAGES.some(x => x === slug);
                // check tags
                const foundTag = NEWSLETTER_EXCLUDED_TAGS.some(x => hasTag(tags, x));
                const primaryTopicStr = typeof primaryTopic === "string" ? primaryTopic : primaryTopic?.slug;
                // check primary topic
                const foundPrimaryTopic = NEWSLETTER_EXCLUDED_PRIMARY_TOPICS.some(x => x === primaryTopicStr);
                if (foundSlug || foundTag || foundPrimaryTopic) {
                    return false;
                }
                break;
            }
            default: {
                break;
            }
        }
        return true;
    };

    if (shouldNewsletterShow()) {
        return <Newsletter />;
    }

    return null;
};

export { EmailCapture };
